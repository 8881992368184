import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { TopNavBar } from './TopNavBar';
import { ChartMenu } from './ChartMenu';
import { RankList } from './RankList';
import { SiteMenu } from './SiteMenu';
import { Calendar } from './Calendar';
import { Box, Button, Center } from '@chakra-ui/react';
import Notice from './Notice';
import YoutubeMV from './YoutubeMV';
import HeroHistoryButton from './HeroHistoryButton';


const chartNameMap = {
    "멜론 TOP100": ["MELON_TOP100_HOURLY", "H"],
    // "멜론 TOP100": ["GS_MELON_TOP100_HOURLY", "H"],
    "멜론 HOT100 (100일)": ["MELON_HOT100_100DAYS_HOURLY", "H"],
    "멜론 실시간차트": ["MELON_REALTIME", "H"],
    "지니 실시간차트": ["GENIE_REALTIME_HOURLY", "H"],
    "FLO 24시간 차트": ["FLO_24H_HOURLY", "H"],
    "벅스 실시간차트": ["BUGS_REALTIME_HOURLY", "H"],
    "멜론 일간차트": ["MELON_DAILY", "D"],
    // "멜론 일간차트": ["GS_MELON_DAILY", "D"],
    "지니 일간차트": ["GENIE_TOP200_DAILY", "D"],
    "바이브 일간차트": ["VIBE_TOTAL_DAILY", "D"],
    "벅스 일간차트": ["BUGS_TOP100_DAILY", "D"],
    "스포티파이 일간차트": ["SPOTIFY_REGIONAL_DAILY", "D"],
    "멜론 주간차트": ["MELON_WEEKLY", "W"],
    "지니 주간차트": ["GENIE_TOP200_WEEKLY", "W"],
    "벅스 주간차트": ["BUGS_TOP100_WEEKLY", "W"],
    "스포티파이 주간차트": ["SPOTIFY_REGIONAL_WEEKLY", "W"],
    "써클디지털 주간차트": ["CIRCLE_DIGITAL_WEEKLY", "W"],
    "써클스밍 주간차트": ["CIRCLE_GLOBAL_WEEKLY", "W"],
    "써클다운 주간차트": ["CIRCLE_GLOBAL_WEEKLY", "W"],
    "써클글로벌 주간차트": ["CIRCLE_GLOBAL_K_POP_WEEKLY", "W"],
    // "써클글로벌 주간차트": ["HERO_CIRCLE_DIGITAL_WEEKLY", "W"],
    // "써클글로벌 주간차트": ["CIRCLE_DIGITAL_WEEKLY", "W"],
    "써클글로벌 일간차트": ["CIRCLE_GLOBAL_K_POP_DAILY", "D"],
    "빌보드 로컬 주간차트": ["BILLBOARD_KOREA_HOT_WEEKLY", "W"],
    "빌보드 글로벌(-美) 주간차트": ["BILLBOARD_GLOBAL_EXCL_US_WEEKLY", "W"],
    "유튜브뮤직 주간차트": ["YOUTUBE_KOREA_SONGS_WEEKLY", "W"],
    "유튜브 뮤비 주간차트": ["YOUTUBE_KOREA_VIDEOS_WEEKLY", "W"],
    "멜론 월간차트": ["MELON_MONTHLY", "M"],
    "지니 월간차트": ["GENIE_TOP200_MONTHLY", "M"],
    "벅스 월간차트": ["BUGS_TOP100_MONTHLY", "M"],

}


export const MainPage = (props) => {
    const [data, setData] = useState({});
    const [chartDateInfo, setChartDateInfo] = useState({}); // {chartName: startDate}
    const [date, setDate] = useState(new Date());
    const [site, setSite] = useState(props.siteMenus[0]);

    const handleDateChange = (newDate) => {
        setDate(newDate);
    }

    const handleSiteChange = (newSite) => {
        setSite(newSite);
    }

    useEffect(() => {
        const fetchLastUpdate = async () => {
            try {
                const minuteStr = new Date().toISOString().slice(0, 18).replace(/-/g, "").replace(/:/g, "");
                const lastUpdateUrl = `https://cdn.hero-chart.kr/chart/hero/last_update.json?v=${minuteStr}`
                console.log(lastUpdateUrl);
                const response = await fetch(lastUpdateUrl, { cache: "no-cache" });
                const lastUpdateData = await response.json();
                console.log(lastUpdateData);
                return lastUpdateData;
            } catch (error) {
                console.error('Error fetching last update data:', error);
                return null;
            }
        };

        // JSON 데이터를 가져오는 함수
        const fetchData = async (chartName, targetDatetime) => {
            try {
                const minuteStr = date.toISOString().slice(0, 18).replace(/-/g, "").replace(/:/g, "");
                const baseURL = "https://cdn.hero-chart.kr/chart/hero";
                const url = `${baseURL}/${chartName}/${targetDatetime}.json?v=${minuteStr}`;
                const response = await fetch(url);
                const jsonData = await response.json();
                setData(prevData => ({
                    ...prevData,
                    [chartName]: jsonData.chart
                }));
                setChartDateInfo(prevData => ({
                    ...prevData,
                    [chartName]: jsonData.date
                }));
                console.log(url, jsonData.date, chartDateInfo[chartName]);

            } catch (error) {
                console.error('Error fetching data:', error);
                setData(prevData => ({
                    ...prevData,
                    [chartName]: []
                }));
            }
        };
        const fetchAndUpdateData = async () => {
            const lastUpdateData = await fetchLastUpdate();
            if (!lastUpdateData) return;

            console.log("####", date.getDate(), date.getHours(), date.getMonth(), date.getFullYear())
            props.chartMap[site].map((siteChartName) => {

                const [chartName, timeUnit] = chartNameMap[siteChartName];
                const yearStr = date.getFullYear().toString();
                const monthStr = (date.getMonth() + 1).toString().padStart(2, "0");
                const dayStr = date.getDate().toString().padStart(2, "0");
                const hourStr = date.getHours().toString().padStart(2, "0");

                let targetDatetime;
                if (timeUnit === "H") {
                    targetDatetime = `${yearStr}${monthStr}${dayStr}${hourStr}`;
                } else if (timeUnit === "M") {
                    targetDatetime = `${yearStr}${monthStr}0100`;
                } else if (timeUnit === "W") {
                    let targetDay;

                    if (chartName.includes("CIRCLE")) {
                        targetDay = 0; // 일요일
                    } else if (chartName.includes("SPOTIFY")) {
                        targetDay = 5; // 목요일
                    } else if (chartName.includes("BILLBOARD")) {
                        targetDay = 0; // 일요일 시작
                    } else if (chartName.includes("YOUTUBE")) {
                        targetDay = 5; // 금요일
                    } else {
                        targetDay = 1; // 월요일
                    }

                    const currentDay = date.getDay();
                    let diff = targetDay - currentDay;

                    // // 미래의 토요일을 찾는 경우 (BILLBOARD)
                    // if (chartName.includes("BILLBOARD")) {
                    //     diff = (diff < 0) ? 7 + diff : diff;
                    //     console.log("===========================")
                    //     console.log("diff", diff)
                    //     console.log("date", date)
                    //     console.log("targetDay", targetDay)
                    //     console.log("currentDay", currentDay)
                    //     console.log("Move ->", date.getDate() + diff)
                    //     console.log("===========================")

                    // } else {
                    //     // 과거의 요일을 찾는 경우
                    //     diff = (diff > 0) ? diff - 7 : diff;
                    // }
                    diff = (diff > 0) ? diff - 7 : diff;

                    const targetDate = new Date(date);
                    targetDate.setDate(date.getDate() + diff);
                    
                    const targetDateStr = targetDate.getDate().toString().padStart(2, "0");
                    const targetMonthStr = (targetDate.getMonth() + 1).toString().padStart(2, "0");
                    const targetYearStr = targetDate.getFullYear().toString();
                    targetDatetime = `${targetYearStr}${targetMonthStr}${targetDateStr}00`;


                } else {
                    targetDatetime = `${yearStr}${monthStr}${dayStr}00`;
                }
                // 최신 업데이트 날짜와 targetDatetime 비교
                const lastUpdateForChart = lastUpdateData[chartName];
                if (lastUpdateForChart && lastUpdateForChart < targetDatetime) {
                    console.log("@@", lastUpdateData[chartName], chartName, lastUpdateData)
                    console.log("Move Last Update" + lastUpdateForChart + " < " + targetDatetime + " " + (targetDatetime - lastUpdateForChart));
                    targetDatetime = lastUpdateForChart;
                }

                console.log(chartName, lastUpdateForChart, targetDatetime, chartDateInfo[chartName]);
                fetchData(chartName, targetDatetime);
            });
        };

        fetchAndUpdateData();

    }, [date, site]);

    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const allowCopy = urlParams.get('allowCopy') === 'true';
  
    const disableCopy = (e) => {
        if (!allowCopy) {
            e.preventDefault();
        }
    }

    return (
        <Box onCopy={disableCopy}>
            <TopNavBar />
            {props.home && <Notice />}
            <Box position="sticky" top="0" zIndex="1000" bg="white">
                <ChartMenu activeMenu={props.activeMenu} />
                {!props.home && <SiteMenu menus={props.siteMenus} handleSiteChange={handleSiteChange} />}
            </Box>
            <Box
                w='100%'
                maxW='100%'
                overflowX='hidden'
            >
                <Box bg='linear-gradient(90deg, #FDFBFB 0%, #EBEDEE 100%)' minH='90vh'>
                    {props.home && <HeroHistoryButton />}
                    {props.home && <YoutubeMV />}
                    {!props.home && <Calendar handleDateChange={handleDateChange} pickerType={props.pickerType} selectedDate={date} />}
                    <Box pb="4rem" >
                        {props.chartMap[site].map((siteChartName) => {
                            const [chartName, timeUnit] = chartNameMap[siteChartName];
                            if (chartName in data) {
                                return (
                                    <React.Fragment key={chartName}>
                                        <RankList
                                            chartName={siteChartName}
                                            siteChartName={siteChartName}
                                            rankData={data[chartName]}
                                            timeUnit={timeUnit}
                                            chartDateInfo={chartDateInfo[chartName]}
                                        />
                                        {props.chartMap[site].indexOf(siteChartName) !== props.chartMap[site].length - 1 &&
                                            <Center>
                                                {/* Divider */}
                                                <Box minW="20.5625rem" maxW="21.5625rem" borderTop='solid 0.00625rem #BCBCBC' />
                                            </Center>}
                                    </React.Fragment>
                                );
                            }
                        })}

                    </Box>
                </Box>
            </Box>
        </Box>

    );
}